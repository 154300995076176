<template>
	<div class="grid">
		<div class="col-12" style="max-width: 1050px;">
			<div class="card">
				<h5>Filtrar por fecha</h5>
				<div class="formgroup-inline">
					<div class="field">
						<label for="fechainicio" style="width: 90px;">Fecha inicio</label>
						<Calendar :showIcon="true" :showButtonBar="true" id="fechainicio" v-model="initialDate" :showTime="true" hourFormat="24"></Calendar>
					</div>
					<div class="field" style="min-width: 2rem%;">
						<label for="fechafinal" style="width: 90px;">Fecha final</label>
						<Calendar :showIcon="true" :showButtonBar="true" id="fechafinal" v-model="finalDate" :showTime="true" hourFormat="24"></Calendar>
					</div>
				</div>
				<div class="formgroup-inline">
					<div class="field" style="min-width: 2rem%;">
						<h5>Tipo de búsqueda</h5>
						<div class="grid">
							<div class="col-12 lg:col-6 md:col-12">
								<div class="field-radiobutton mb-0">
									<RadioButton id="option1" name="option" value="all" v-model="searchType" />
									<label for="option1">Todos los registros</label>
								</div>
							</div>
							<div class="col-12 lg:col-6 md:col-12">
								<div class="field-radiobutton mb-0">
									<RadioButton id="option2" name="option" value="collected" v-model="searchType" />
									<label for="option2">Cobradas / Pagadas</label>
								</div>
							</div>
						</div>
					</div>
					<Button label="Buscar" :loading="loadingSearchButton" class="p-button-success mr-2 mb-2 searchButton" @click="getBalance"></Button>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="card">
				<h5>Resultados del <b> {{ this.initialDate.toLocaleDateString("es-ES", dateFormatOptions) }} </b> al <b> {{ this.finalDate.toLocaleDateString("es-ES", dateFormatOptions) }} </b></h5>
				<div class="col-12 formgroup-inline">
					<div class="col-12 md:col-6">
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Ingresos efectivo:</b></h6>
							<h6 style="margin: 0px; color: green">${{ this.cashIncome.toFixed(2) }}</h6>
						</div>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Ingresos transferencia:</b></h6>
							<h6 style="margin: 0px; color: green">${{ this.transferIncome.toFixed(2) }}</h6>
						</div>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Ingresos tarj. deb.:</b></h6>
							<h6 style="margin: 0px; color: green">${{ this.debitCardIncome.toFixed(2) }}</h6>
						</div>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Ingresos tarj. cred.:</b></h6>
							<h6 style="margin: 0px; color: green">${{ this.creditCardIncome.toFixed(2) }}</h6>
						</div>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Ingresos cheque:</b></h6>
							<h6 style="margin: 0px; color: green">${{ this.checkIncome.toFixed(2) }}</h6>
						</div>
						<br/>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Egresos efectivo:</b></h6>
							<h6 style="margin: 0px; color: red">${{ this.cashExpense.toFixed(2) }}</h6>
						</div>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Egresos transferencia:</b></h6>
							<h6 style="margin: 0px; color: red">${{ this.transferExpense.toFixed(2) }}</h6>
						</div>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Egresos tarjeta:</b></h6>
							<h6 style="margin: 0px; color: red">${{ this.cardExpense.toFixed(2) }}</h6>
						</div>
						<div style="display: flex;">
							<h6 style="margin: 0px; width: 15rem;"><b>Egresos cheque:</b></h6>
							<h6 style="margin: 0px; color: red">${{ this.checkExpense.toFixed(2) }}</h6>
						</div>
					</div>
					<div class="col-12 md:col-6">
						<div style="display: flex;">
							<h5 style="margin: 0px; width: 10rem;"><b>Ingresos:</b></h5>
							<h5 style="margin: 0px;">${{ this.income.toFixed(2) }}</h5>
						</div>
						<div style="display: flex;">
							<h5 style="margin: 0px; width: 10rem;"><b>Egresos:</b></h5>
							<h5 style="margin: 0px;">${{ this.expenses.toFixed(2) }}</h5>
						</div>
						<div style="display: flex;">
							<h5 style="margin: 0px; width: 10rem;"><b>Total:</b></h5>
							<h5 :style="{margin: '0px', color: (this.total>=0) ? 'green' : 'red'}">${{ this.total.toFixed(2) }}</h5>
						</div>
					</div>
				</div>
				
				<h5>Detalle de transacciones</h5>
				<Accordion>
					<AccordionTab :header="'Ingresos (' + this.sales.length + ')'">
						<DataTable ref="dt" :value="sales" selectionMode="single" dataKey="id" :paginator="true" :rows="20"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20,35,50]"
									currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} ingresos" responsiveLayout="scroll">
							<Column field="serialNumber" header="Id" :sortable="true" headerStyle="width:10%; min-width:8rem;">
								<template #body="slotProps">
									<span class="p-column-title">Serie</span>
									{{slotProps.data.serialNumber !== null && slotProps.data.serialNumber !== undefined ? slotProps.data.serialNumber : slotProps.data.id.toString().padStart(9, "0") }}
								</template>
							</Column>
							<Column field="customer.name" header="Cliente" :sortable="true" headerStyle="width:25%; min-width:10rem;">
								<template #body="slotProps">
									<span class="p-column-title">Cliente</span>
									{{slotProps.data.customer.name}}
								</template>
							</Column>
							<Column field="total" header="Total" :sortable="true" headerStyle="width:20%; min-width:10rem;">
								<template #body="slotProps">
									<span class="p-column-title">Total</span>
									{{"$"+slotProps.data.total.toFixed(2)}}
								</template>
							</Column>
							<Column field="createdAtFormatted" header="Fecha" :sortable="true" headerStyle="width:25%; min-width:10rem;">
								<template #body="slotProps">
									<span class="p-column-title">Fecha</span>
									{{slotProps.data.createdAtFormatted}}
								</template>
							</Column>
							<Column field="paymentMethod" header="Met. Pago" :sortable="true" headerStyle="width:15%; min-width:10rem;">
								<template #body="slotProps">
									<span :class="'status-badge status-' + slotProps.data.paymentMethodBadgeColor">{{slotProps.data.paymentMethodReadable}}</span>
								</template>
							</Column>
							<Column field="state" header="Estado" :sortable="true" headerStyle="width:20%; min-width:10rem;">
								<template #body="slotProps">
									<span :class="'status-badge status-' + slotProps.data.receiptStateBadgeColor">{{slotProps.data.receiptState}}</span>
								</template>
							</Column>
							<Column field="collectStatusReadable" header="Cobranza" :sortable="true" headerStyle="width:20%; min-width:10rem;">
								<template #body="slotProps">
									<span :class="'status-badge status-' + slotProps.data.collectStatusBadgeColor">{{slotProps.data.collectStatusReadable}}</span>
								</template>
							</Column>
						</DataTable>
					</AccordionTab>
					<AccordionTab :header="'Egresos (' + this.purchases.length + ')'">
						<DataTable ref="dt" :value="purchases" selectionMode="single" dataKey="id" :paginator="true" :rows="20"
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20,35,50]"
									currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} egresos" responsiveLayout="scroll">
							<Column field="serial" header="Serial" :sortable="true" headerStyle="width:10%; min-width:8rem;">
								<template #body="slotProps">
									<span class="p-column-title">Serial</span>
									{{ slotProps.data.serialNumber ? slotProps.data.serialNumber : slotProps.data.id.toString().padStart(9, "0") }}
								</template>
							</Column>
							<Column field="customer.name" header="Razón Social / Descripción" :sortable="true" headerStyle="width:30%; min-width:10rem;">
								<template #body="slotProps">
									<span class="p-column-title">Razón Social / Descripción</span>
									{{ slotProps.data.vendorName ? slotProps.data.vendorName : slotProps.data.reason }}
								</template>
							</Column>
							<Column field="total" header="Total" :sortable="true" headerStyle="width:20%; min-width:10rem;">
								<template #body="slotProps">
									<span class="p-column-title">Total</span>
									{{"$"+slotProps.data.total.toFixed(2)}}
								</template>
							</Column>
							<Column field="createdAtFormatted" header="Fecha" :sortable="true" headerStyle="width:25%; min-width:10rem;">
								<template #body="slotProps">
									<span class="p-column-title">Fecha</span>
									{{slotProps.data.createdAtFormatted}}
								</template>
							</Column>
							<Column field="collectStatusReadable" header="Estado Pago" :sortable="true" headerStyle="width:25%; min-width:10rem;">
								<template #body="slotProps">
									<span :class="'status-badge status-' + slotProps.data.collectStatusBadgeColor">{{slotProps.data.collectStatusReadable}}</span>
								</template>
							</Column>
						</DataTable>
					</AccordionTab>
				</Accordion>
				<Toast/>
				<Button label="Imprimir" :loading="loadingPrintButton" class="p-button-success mr-2 mb-2" style="width: 150px; margin: 10px 0px 0px auto;" @click="printBalance"></Button>
			</div>
		</div>
	</div>

</template>

<script>
import httpRequest from '../../service/httpRequest';
import Sale from '../../models/sale';
import Purchase from '../../models/purchase';

export default {
	data() {
		return {
			sales: [],
            purchases: [],
			income: 0.0,
			expenses: 0.0,
			total: 0.0,
			cashIncome: 0.0,
			cashExpense: 0.0,
			transferIncome: 0.0,
			transferExpense: 0.0,
			debitCardIncome: 0.0,
			creditCardIncome: 0.0,
			cardExpense: 0.0,
			checkIncome: 0.0,
			checkExpense: 0.0,
			initialDate: new Date(),
			finalDate: new Date(),
			searchType: "all",
			dateFormatOptions: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' },

			// Loading
			loadingSearchButton: false,
			loadingPrintButton: false,
		}
	},
	created() {
		this.initialDate = new Date();
		this.finalDate = new Date();
		this.initialDate.setHours(0,0,0);
		this.finalDate.setHours(23,59,59);
        this.getBalance();
	},
	mounted() {
    },
	methods: {
		async getBalance() {
			this.loadingSearchButton = true;
			// Fix date
			const initialDate_ = this.initialDate.toLocaleDateString("es-ES", this.dateFormatOptions);
			const finalDate_ = this.finalDate.toLocaleDateString("es-ES", this.dateFormatOptions);
            let response = await httpRequest.getBalance(initialDate_, finalDate_, this.searchType);
			this.loadingSearchButton = false;
            if (response.status == 200) {
				this.income = response.data.income;
				this.expenses = response.data.expenses;
				this.total = this.income - this.expenses;
				// Cash
				this.cashIncome = response.data.cashIncome;
				this.cashExpense = response.data.cashExpense;
				// Transfer
				this.transferIncome = response.data.transferIncome;
				this.transferExpense = response.data.transferExpense;
				// Card
				this.debitCardIncome = response.data.debitCardIncome;
				this.creditCardIncome = response.data.creditCardIncome;
				this.cardExpense = response.data.cardExpense;
				// Check
				this.checkIncome = response.data.checkIncome;
				this.checkExpense = response.data.checkExpense;
				// Movement detail
				this.sales = response.data.sales.map(function(data) { return new Sale(data)});
				this.purchases = response.data.purchases.map(function(data) { return new Purchase(data)});
            } else { console.log("Error: ", response.status); }
        },

		async printBalance() {
			// Prepare data
			let data = {
				initialDate: this.initialDate,
				finalDate: this.finalDate,
				income: this.income,
				expenses: this.expenses,
				total: this.total,
				cashIncome: this.cashIncome,
				cashExpense: this.cashExpense,
				transferIncome: this.transferIncome,
				transferExpense: this.transferExpense,
				debitCardIncome: this.debitCardIncome,
				creditCardIncome: this.creditCardIncome,
				cardExpense: this.cardExpense,
				checkIncome: this.checkIncome,
				checkExpense: this.checkExpense,
			}

			this.loadingPrintButton = true;
			let response = await httpRequest.printBalance(data);
			this.loadingPrintButton = false;
			if (response.status == 200) { console.log(200); } else { console.log(200); }
		},
	}
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';

@media screen and (max-width: 769px) {
	#table-header-inputs-container {
		display: block;
	}
	#table-search-input {
		margin: 15px 0px !important;
	}
}
@media screen and (min-width: 769px) {
	#table-header-inputs-container {
		display: flex;
	}
	#table-search-input {
		margin: 0px 15px;
	}
}

.searchButton { 
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.status-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.status-badge {
    &.status-green {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-red {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-yellow {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-orange {
        background: #ffa177;
        color: #da2727;
    }

	&.status-blue {
        background: #B3E5FC;
        color: #23677B;
    }

	&.status-gray {
        background: #d6d8d9;
        color: #464747;
    }
}
</style>
